.logo {
    width: 160px;
    height: auto;
}
.nav-link, .nav-item {
    margin-right: 20px;
    font-family: var(--font-theme);
    font-weight: 400;
    font-size: 18px;
    width: auto;
}

.nav-item.dropdown:hover .dropdown-menu {
    display: block;
}

.nav-link {
  background: linear-gradient(0deg, var(--color-blue), var(--color-blue)) no-repeat right bottom / 0 var(--bg-h);
  transition: background-size 450ms;
  --bg-h: 100%;
}
.nav-link:where(:hover, :focus-visible) {
  background-size: 100% var(--bg-h);
  background-position-x: left;
}
.nav-link:hover,.nav-link.active:hover {
  color: var(--color-white);
}
.navbar-toggler:focus {
    box-shadow: none;
}

.sticky-top {
    z-index: 10;
}

@media screen and (max-width: 768px) {
    .offcanvas {
        background-color: var(--color-blue);
        color: var(--color-white);
    }
    .offcanvas .nav-link, .nav-item {
    margin-right: auto;
    font-weight: 400;
    font-size: 24px;
    width: auto;
    margin-left: auto;
    margin-bottom: 5%;
    width: 100%;
    text-align: center;
}
.offcanvas .nav-link:hover, .nav-item:hover {
    background: var(--color-linkedin);
}
.logo {
    width: 120px;
    height: auto;
}
}