.rounded {
    border-radius: 5px !important;
    overflow: hidden;
    position: relative;
}
.roundedCircle {
    border-radius: 50%;
    overflow: hidden;
    height: auto;
}

/*section-style-1*/
.section-style-1 .main-text {
    margin-bottom: .5rem;
}
.section-style-1 .main-sub-text {
    font-style: italic;
}

/*section-style-2*/
.section-style-2 .main-text {
    margin-bottom: .5rem;
}
.section-style-2 .main-sub-text {
    font-style: italic;
}

.services-rounded .title {
    font-size: 16px;
}

.bg-blue {
    background-color: var(--color-blue);
}

.section-style-1 {
    padding-top: 2rem;
    min-height: 260px;
}

.section-style-2 {
    padding-top: 2rem;
    min-height: 500px;
}

.section-style-2.margin-top-neg-175 {
    margin-top: -150px;
}

.descontent {
    position: absolute;
    top: 95%;
    width: 100%;
    background-color: var(--color-blue);
    color: var(--color-white);
    padding: 1rem;
    z-index: 0;
    border-radius: 5px;
}

.descontent:after {
    position: absolute;
    content: "";
    height: 115%;
    width: 100%;
    z-index: -1;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: #1590ca6c;
    border-radius: 5px;
    transition: all .6s ease-in-out;
}

@media screen and (max-width: 768px) {
   .services-rounded .title {
    font-size: 28px;
} 
.section-style-1 {
    padding-top: 2rem;
    min-height: auto;
    padding-bottom: 2rem;
}
.section-style-2.margin-top-neg-175 {
    margin-top: auto;
}
.descontent {
    position: relative;
}
}