.parallax {
    background-image: url('../../assets/images/slider-images/6.jpg');
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.parallax-overlay{
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0,0,0,.6);
    z-index: 0;
}
.parallax.height-25 {
    height: 250px;
}
.parallax.height-35 {
    height: 350px;
}
.parallax.height-45 {
    height: 450px;
}
.parallax.height-55 {
    height: 550px;
}
.parallax-body {
    z-index: 1;
}
