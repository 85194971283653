.carousel {
    position: relative;
    height: 100vh;
    overflow: hidden;
}
.carousel-indicators {
    margin-bottom: 7%;
}
.carousel-caption {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    text-align: start;
    width: 30%;
}
.carousel-caption h1 {
    font-weight: 600;
    font-size: 55px;
}

@media screen and (max-width: 768px) {
    .carousel {
    position: relative;
    height: 60vh;
    overflow: hidden;
}
    .carousel-caption h3 {
        font-size: 16px;
    }    
    .carousel-item img{
    height: 60vh;
    width: auto !important;
    object-fit: none;
  object-position: right;
}
.carousel-caption {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    text-align: start;
    width: 80%;    
    text-align: center;
}
.carousel-caption h1 {
    font-weight: 600;
    font-size: 35px;
}

.carousel-item .btn-theme {
    background-color: var(--color-blue);
    border-radius: 0;
    transition: all ease-in-out .3s;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
}

}