/*about-style-1*/
.about-style-1 .main-text {
  margin-bottom: 0.5rem;
}
.about-style-1 .main-sub-text {
  font-style: italic;
}

/*about-style-2*/
.about-style-2 .main-text {
  margin-bottom: 0.5rem;
}
.about-style-2 .main-sub-text {
  font-style: italic;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.aboutrotateslider {
  position: absolute;
  bottom: 5%;
  left: 2%;
  -webkit-animation: spin 30s linear infinite;
  -moz-animation: spin 30s linear infinite;
  animation: spin 30s linear infinite;
  z-index: 0;
  width: 600px;
}

.about-img {
  border-radius: 5px;
  position: relative;
  z-index: 0;
}

.about-style-1 .card {
  background-color: var(--color-blue);
  color: var(--color-white);
}

@media screen and (max-width: 768px) {
  .circle {
    margin-left: 50%;
    transform: translateX(-15%);
  }
}
