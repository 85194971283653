@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@400;600&display=swap');
:root {
  --color-dark: #2d394b;
  --color-linkedin: #0072b1;
  --color-facebook: #1877F2;
  --color-instagram: #E1306C;
  --color-whatsapp: #25D366;
  --color-twitter: #1DA1F2;
  --color-youtube: #FF0000;
  --color-white: #ffffff;
  --color-light: #eeeeee;
  --color-black: #000000;
  --color-blue: #1590ca;
  --font-theme: "Outfit", sans-serif;
  --animate-delay: 0.5s;
}

html,
body {
  font-family: var(--font-theme);
  color: var(--color-dark);
  -webkit-font-smoothing: antialiased !important;
  scroll-behavior: smooth;
}

.text-justify {
  text-align: justify !important;
}

.btn-theme {
  background-color: var(--color-blue);
  border-radius: 0;
  transition: all ease-in-out .3s;
  width: max-content;
}

.btn-theme:hover {
  background-color: var(--color-linkedin);
  color: var(--color-white);
}

.w-40 {
    width: 40%!important;
}

#left-tabs-container .list-marker {
  color: var(--color-blue);
}
#left-tabs-container .nav-link:hover {
  color: var(--color-blue);
}
#left-tabs-container .nav-link.active .list-marker {
  color: var(--color-white);
}
#left-tabs-container .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: var(--color-white);
    background-color: var(--color-blue);
    border-radius: 5px !important;
}
