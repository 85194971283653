.page-banner {
    position: relative;
    padding: 0;
    color: #ffffff;
    background: var(--theme-color-black);
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.page-banner .image-layer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    /* opacity: 0.4;
    -webkit-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -moz-filter: grayscale(100%); */
}

.page-banner .image-layer {
    background-image: url("../../assets/images/header-tech-1.jpg");
    box-shadow: 0px 0px 0px 5000px #1590caa6 inset;
}

.page-banner .banner-inner {
    position: relative;
    display: block;
    text-align: center;
    z-index: 3;
}

.page-banner .inner-container {
    position: relative;
}

.page-banner h1 {
    position: relative;
    font-size: 60px;
    color: #ffffff;
    line-height: 0.9em;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 0 0 20px 0;
    word-break: break-word;
}

.page-banner .page-nav {
    position: relative;
    padding-top: 5px;
    text-align: center;
}
.clearfix::after {
    display: block;
    clear: both;
    content: "";
}

.page-banner .bread-crumb {
    position: relative;
    display: inline-block;
    margin: 0;
    padding: 0;
    list-style: none;
}

.page-banner .bread-crumb li {
    position: relative;
    float: left;
    font-size: 20px;
    line-height: 30px;
    color: #ccd6df;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: default;
    padding-right: 15px;
    margin-right: 15px;
}

.page-banner .bread-crumb li:before {
    position: absolute;
    right: -15px;
    width: 30px;
    text-align: center;
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    content: "-";
}

.page-banner .bread-crumb li:last-child {
  padding-right: 0;
  margin-right: 0;
}

.page-banner .bread-crumb li:last-child:before {
  display: none;
}

.page-banner .bread-crumb li a {
    color: #ffffff;
    font-weight: 400;
    transition: all 300ms ease;
}

.page-banner .bread-crumb li a:hover, .page-banner .bread-crumb li.active {
    color: var(--theme-color-orange);
}

.page-banner .bread-crumb li:last-child {
    padding-right: 0;
    margin-right: 0;
}

@media screen and (max-width: 768px) {
    .page-banner h1 {
        font-size: 40px;
    }
    .page-banner .bread-crumb li {
        font-size: 16px;
    }
    .page-banner {
        height: 200px;
    }
}